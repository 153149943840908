.data-management-solutions-container {
  .custom-card {
    position: relative;
    width: 379px; /* Example width */
    height: 275px; /* Example height */
    border: none; /* Ensure no full border */
    background: rgba(255, 236, 224, 1);
    box-shadow: 0px 0px 4px 0px rgba(88, 88, 88, 0.25);

    .card-body {
      display: flex;
      align-items: center;
      // justify-content: center;
      flex-direction: column;
    }
    .card-title {
      font-weight: 800;
      font-size: 24px;
      text-align: center;
      height: 200;
      color: rgba(73, 73, 73, 1);
      max-width: 292px;
    }
    .card-description {
      font-weight: 400;
      font-size: 20px;
      text-align: center;
      height: 100%;
      justify-content: center;
      display: flex;
      align-items: center;
    }
  }
  .custom-card::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 50%;
    border-top: 4px solid rgba(240, 106, 48, 1); /* Top border */
    border-left: 4px solid rgba(240, 106, 48, 1); /* Left border */
  }

  .custom-card::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 50%;
    height: 50%;
    border-bottom: 4px solid rgba(240, 106, 48, 1); /* Bottom border */
    border-right: 4px solid rgba(240, 106, 48, 1); /* Right border */
  }
}

/* ContactForm.scss */

/* General styles for the form */
.contact-form {
  .form-group {
    margin-bottom: 1.5rem;
  }

  .form-control {
    border: none;
    border-bottom: 1px solid #000;
    border-radius: 0;
    box-shadow: none;
    padding: 0.5rem 0.75rem;
  }

  .form-select {
    border: none;
    border-bottom: 1px solid #000;
    border-radius: 0;
    box-shadow: none;
    padding: 0.5rem 0.75rem;
  }

  .btn-submit {
    border-radius: 30px;
    padding: 10px 40px;
    background-color: #343a40;
    color: white;
    border: none;
    &:hover {
      background-color: #495057;
    }
  }

  /* Title and description styles */
  .contact-form-title {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 1rem;
  }

  .contact-form-description {
    font-size: 16px;
    color: #cccccc;
    margin-bottom: 2rem;
    max-width: 70%;
    margin-left: auto;
    margin-right: auto;
  }
}

// partner-logos
.partner-logos {
  background-color: white;
  padding: 1rem;
  border-radius: 0.5rem;
  // margin-bottom: 2rem;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-bottom: 0;
  height: 130px;

  .logo-slider {
    position: relative;
    width: 100%;
    height: 5rem;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    .logo-track {
      display: flex;
      align-items: center;
      gap: 4rem;
      position: absolute;
      will-change: transform;
      animation: infiniteScroll 25s linear infinite;
      width: max-content; // Ensure the track takes full width of content

      // Pause animation on hover
      &:hover {
        animation-play-state: paused;
      }

      .logo-wrapper {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 60px;
        width: 150px;
        img {
          height: 100%;
          width: 100%;
          object-fit: contain;
        }
        // @media (max-width: 768px) {
        //   height: 2.5rem;
        // }

        // @media (max-width: 480px) {
        //   height: 2rem;
        // }
      }
    }
  }
}

@keyframes infiniteScroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-50% - 2rem)); // Adjust based on gap
  }
}
