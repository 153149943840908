body {
  margin: 0;
  font-family: "Nunito Sans", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: "Nunito Sans", serif;
}

.font-family{
  font-family: "Nunito Sans", serif;
}


.primary-text-color{
  color: rgba(240, 106, 48, 1);
  font-family: "Nunito Sans", serif;
}

.primary-bg{
  background-color: rgba(240, 106, 48, 1);
}

.title-text{
  font-size: 2.8rem
}

.go-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  border: none;
  border-radius: 50% !important;
  color: white;
  width: 50px;
  height: 50px;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
}


// custom.scss

// Override Bootstrap primary color
$primary: rgba(240, 106, 48, 1); // Replace with your desired primary color

// Import Bootstrap's SCSS
@import "~bootstrap/scss/bootstrap";
