$transition-speed: 0.3s;
$primary-color: #ff9e5c;
$secondary-color: rgba(255, 240, 229, 1);
$nav-hover-color: rgba(216, 216, 216, 1);

.tab-container {
  padding: 40px 20px;
  border-radius: 8px;
  background-color: #f8f9fa;

  .tab-title {
    text-align: center;
    margin-bottom: 15px;
  }

  .tab-subtitle {
    text-align: center;
    font-size: 24px;
    color: #6c757d;
    margin-bottom: 40px;
  }

  .equal-height {
    display: flex;
    align-items: stretch;

    .left-menu {
      background-color: #f0f0f0;
      display: flex;
      flex-direction: column;
      padding: 0;
    }

    .right-content {
      background-color: #fff;
      padding: 10px;
    }
  }

  .nav-pills {
    .nav-item {
      margin: 0;
      min-width: 216px;

      .nav-link {
        text-align: center;
        padding: 15px;
        font-size: 18px;
        font-weight: 400;
        border-radius: 0;
        outline: none;
        box-shadow: none;
        color: #000;
        position: relative;
        z-index: 1;
        background-color: $nav-hover-color;
        transition: background-color $transition-speed, color $transition-speed;
        &:hover {
          background-color: lighten($nav-hover-color, 10%);
        }

        &.active {
          background-color: $primary-color;
          color: #fff;
        }
      }
    }
  }

  .tab-content {
    .tab-pane {
      padding: 0px;
      opacity: 0;
      transform: translateY(10px);
      transition: opacity $transition-speed, transform $transition-speed;

      &.active {
        opacity: 1;
        transform: translateY(0);
      }

      .tab-description {
        font-size: 24px;
        color: #6c757d;
        margin-bottom: 20px;
        font-weight: 600;
        padding: 10px;
      }

      .tab-image {
        width: 100%;
        height: 100%;
        border-radius: 8px;
        transition: transform $transition-speed;

        &:hover {
          transform: scale(1.05);
        }
      }
    }
  }
}


/* xs Screen Styles (max-width: 576px) */
@media only screen and (max-width: 576px) {
 .tab-container{
  .nav-pills {
    .nav-item {
      width: 100%;
 }
}
 }
}